import PouchDB from "pouchdb-browser";
import PouchAuth from "pouchdb-authentication";
import get from "lodash/get";
import includes from "lodash/includes";
import random from "lodash/random";
import each from "lodash/each";
import map from "lodash/map";
import reject from "lodash/reject";
import startsWith from "lodash/startsWith";
import find from "lodash/find";
import toLower from "lodash/toLower";
import download from "downloadjs";
import { format } from "date-fns";
import { store } from "store";
import { errorReporter } from "util/errorReporter";

import {
  removeMeet,
  meetIsSyncing,
  meetIsNotSyncing,
  meetIsActivelySyncing,
  meetIsNotActivelySyncing,
  startLoadingMeet,
  doneLoadingMeet,
  meetIsLocal,
  meetIsOnline,
  meetNotFound,
  updateCalculatedData,
} from "actions";

import {
  MEET_DOC_PREFIX,
  RESTRICTED_MEET_DOC_PREFIX,
  generateId,
  getDocType,
} from "util/docHelper";

import { updateAttributesOnDocument } from "util/pouchActions";

import { login, checkAuthStatus } from "util/pouchAuth";

import { COUCH_DB_SERVER, fetchWrapper } from "util/api";
import { GenericDocument, MeetDocument } from "types";
import { handleDatabaseChange } from "./handleDatabaseChange";

PouchDB.plugin(PouchAuth);
type PouchDbDatabase = PouchDB.Database & {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  adapter: string;
};

// local
const MASTER_DB_NAME = "liftingcast";

const DB_INSTANCES: Record<string, PouchDbDatabase> = {};
let DB_SYNC: PouchDB.Replication.Sync<{}> | null = null;
let DB_CHANGE: PouchDB.Core.Changes<{}> | null = null;

export const getMasterDbInstance = function () {
  if (!DB_INSTANCES[MASTER_DB_NAME]) {
    DB_INSTANCES[MASTER_DB_NAME] = new PouchDB(
      MASTER_DB_NAME
    ) as PouchDbDatabase;
  }

  return DB_INSTANCES[MASTER_DB_NAME];
};

export const getMeetDbInstance = function (meetId: string) {
  if (!DB_INSTANCES[meetId]) {
    DB_INSTANCES[meetId] = new PouchDB(meetId, {
      auto_compaction: true,
    }) as PouchDbDatabase;
    if (DB_INSTANCES[meetId].adapter !== "idb") {
      console.error(
        "Meet not using idb adapter",
        meetId,
        DB_INSTANCES[meetId].adapter,
        navigator.userAgent
      );
    }
  }
  return DB_INSTANCES[meetId];
};

export const getOnlineMeetDbInstance = function (meetId: string) {
  console.log("writable online db:", meetId);
  const key = `online-${meetId}`;
  if (!DB_INSTANCES[key]) {
    DB_INSTANCES[key] = new PouchDB(`${COUCH_DB_SERVER}/${meetId}`, {
      skip_setup: true,
    }) as PouchDbDatabase;
  }

  return DB_INSTANCES[key];
};

const getReadOnlyOnlineMeetDbInstance = function (meetId: string) {
  console.log("read only online db:", meetId);
  const readOnlyDatabaseId = `${meetId}_readonly`;
  const key = `online-${readOnlyDatabaseId}`;
  if (!DB_INSTANCES[key]) {
    DB_INSTANCES[key] = new PouchDB(
      `${COUCH_DB_SERVER}/${readOnlyDatabaseId}`,
      { skip_setup: true }
    ) as PouchDbDatabase;
  }

  return DB_INSTANCES[key];
};

export const unWatchMeet = function () {
  if (DB_CHANGE && DB_CHANGE.cancel) {
    DB_CHANGE.cancel();
  }
};

export const watchMeet = function (meetId: string, db: PouchDbDatabase) {
  db = db || getMeetDbInstance(meetId);

  if (DB_CHANGE && DB_CHANGE.cancel) {
    DB_CHANGE.cancel();
  }

  // TODO: might need to know the exact sequence number to use for since to avoid missing anything.
  console.log("watching meet");
  DB_CHANGE = db
    .changes({
      since: "now", // if available use update_seq from all docs response
      live: true,
      include_docs: true,
      conflicts: true,
    })
    .on("change", function (change) {
      handleDatabaseChange({
        doc: change.doc as unknown as GenericDocument,
        isDeleted: change.deleted,
        meetId,
        isInitialLoad: false,
        store,
      });
    })
    .on("error", function (err) {
      console.log("ERROR watching meet:", err);
      // this happens a lot if computer goes to sleep or loses internet connection
      // need to load meet from scratch.
      if (includes(window.location.pathname, "registration")) {
        console.log(
          "ERROR: Skipping reload because user is on registration page"
        );
      } else {
        const waitTimeMS = random(6000, 40000);
        console.log(`Possibly offline. Waiting ${waitTimeMS}`);
        setTimeout(() => {
          loadMeet(meetId, db);
        }, waitTimeMS);
      }
    });
};

export const loadMeet = function (meetId: string, db?: PouchDbDatabase) {
  console.log("loading meet:", meetId);
  store.dispatch(startLoadingMeet(meetId));

  // we only pass in the read only online db
  db = db || getMeetDbInstance(meetId);

  return db
    .allDocs({
      include_docs: true,
      conflicts: true,
    })
    .then((result) => {
      console.log("loading meet...");
      each(result.rows, (row) => {
        handleDatabaseChange({
          doc: row.doc as unknown as GenericDocument,
          isDeleted: false,
          meetId,
          isInitialLoad: true,
          store,
        });
      });
      store.dispatch(updateCalculatedData({ meetId }));
      watchMeet(meetId, db as PouchDbDatabase);
      console.log("Finished loading meet");
      // extra loading screen time for sync to setup.
      return setTimeout(() => store.dispatch(doneLoadingMeet(meetId)), 1000);
    })
    .catch((error) => {
      if (error.status === 404) {
        return store.dispatch(meetNotFound(meetId));
      } else {
        console.log("Failed to load meet: ", error);
        const waitTimeMS = random(4000, 20000);
        console.log(`Possibly offline. Waiting ${waitTimeMS}`);
        setTimeout(() => {
          loadMeet(meetId, db);
        }, waitTimeMS);
      }
    });
};

export const exportMeet = function (meetId: string) {
  const db = getMeetDbInstance(meetId);

  return db.allDocs({ include_docs: true }).then((result) => {
    let docs = map(result.rows, (row) => {
      const doc = row.doc as unknown as GenericDocument;
      if (getDocType(doc) === RESTRICTED_MEET_DOC_PREFIX) {
        if (
          get(doc, "stripeSecretKey") &&
          get(doc, "stripeSecretKey").includes("sk_")
        ) {
          delete doc.stripeSecretKey;
        }
        delete doc.changes;
      }
      return doc;
    });
    docs = reject(docs, (doc) => startsWith(doc?._id, "_"));
    const docsAsString = JSON.stringify(docs);

    const meet: MeetDocument | undefined = find(
      docs,
      (doc) => getDocType(doc as unknown as GenericDocument) === MEET_DOC_PREFIX
    );

    download(
      new Blob([docsAsString], { type: "text/csv;charset=utf-8" }),
      `${toLower(meet?.name)}_${format(new Date(), "yyyy_MM_dd_hh_mm")}.json`,
      "text/json"
    );
  });
};

export const importMeet = (docString: string) => {
  try {
    const docs = JSON.parse(docString);

    const meet = find(docs, (doc) => getDocType(doc) === MEET_DOC_PREFIX);

    meet._id = generateId(MEET_DOC_PREFIX);
    meet.name = `${meet.name} (copy)`;
    meet.validated = false;
    const db = getMeetDbInstance(meet._id);
    const masterDb = getMasterDbInstance();
    masterDb.put({ _id: meet._id, online: false });
    store.dispatch(meetIsNotSyncing(meet._id));
    delete meet._rev;
    db.put(meet);

    each(docs, (doc) => {
      if (getDocType(doc) === RESTRICTED_MEET_DOC_PREFIX) {
        if (
          get(doc, "stripeSecretKey") &&
          get(doc, "stripeSecretKey").includes("sk_")
        ) {
          delete doc.stripeSecretKey;
        }
        delete doc.changes;
      }
      if (getDocType(doc) !== MEET_DOC_PREFIX) {
        delete doc._rev;
        db.put(doc);
      }
    });
    return meet._id;
  } catch (e) {
    alert("Failed to import meet data file.");
    console.log(e);
  }
};

export const createLocalMeetRecordIfNeeded = async function (meetId: string) {
  return new Promise<void>(async (resolve) => {
    const masterDb = getMasterDbInstance();
    const result = await masterDb.allDocs({ key: meetId, include_docs: true });
    if (result.rows.length === 0) {
      masterDb.put({ _id: meetId, online: true }).then(() => resolve());
    } else {
      resolve();
    }
  });
};

export const disableSync = function (meetId: string) {
  store.dispatch(meetIsNotSyncing(meetId));
  DB_SYNC && DB_SYNC.cancel && DB_SYNC.cancel();
};

const initialSyncFromCloud = function (meetId: string) {
  console.log("initialSyncFromCloud Start");
  const onlineMeetDb = getOnlineMeetDbInstance(meetId);
  const meetDb = getMeetDbInstance(meetId);

  store.dispatch(startLoadingMeet(meetId));
  return new Promise<void>((resolve) => {
    // TODO: issue where you can get here, redux state says you are logged in but you are not. Possibly because you logged into another meet
    const isLoggedIn = get(
      store.getState(),
      ["meetMetaData", meetId, "isLoggedIn"],
      false
    );
    if (!isLoggedIn) {
      console.log("Not logged in. Not doing initialSyncFromCloud.");
      return resolve();
    }
    return onlineMeetDb.replicate
      .to(meetDb)
      .on("complete", () => {
        console.log("initialSyncFromCloud Finished");
        resolve();
      })
      .on("error", function (error: any) {
        console.log("Error on initial db replication. Skipping.", error);
        //  if error.reason is "QuotaExceededError"
        errorReporter({
          message: "Error on initial db replication.",
          error,
        });
        resolve();
      });
  });
};

export const syncToCloud = async function (meetId: string) {
  console.log("about to syncToCloud", meetId);
  const onlineMeetDb = getOnlineMeetDbInstance(meetId);
  const meetDb = getMeetDbInstance(meetId);
  store.dispatch(meetIsOnline(meetId));

  await createLocalMeetRecordIfNeeded(meetId);
  await initialSyncFromCloud(meetId);
  await loadMeet(meetId);

  console.log("Syncing: ", meetId);

  if (DB_SYNC && DB_SYNC.cancel) {
    DB_SYNC.cancel();
  }

  const isLoggedIn = get(
    store.getState(),
    ["meetMetaData", meetId, "isLoggedIn"],
    false
  );

  if (isLoggedIn) {
    console.log("Logged in and about to start sync.");
    DB_SYNC = meetDb
      .sync(onlineMeetDb, {
        back_off_function: (delay) => {
          if (!delay) {
            return 1000;
          }
          store.dispatch(meetIsNotSyncing(meetId));
          return 2000;
        },
        live: true,
        retry: true,
        since: 0,
      })
      .on("change", (info) => {
        // console.log("Sync change:", info);
      })
      .on("paused", (err) => {
        // replication paused (e.g. replication up to date, user went offline)
        // console.log("Sync paused:", err);
        store.dispatch(meetIsNotActivelySyncing(meetId));
        if (!navigator.onLine) {
          store.dispatch(meetIsNotSyncing(meetId));
        }
      })
      .on("active", () => {
        // replicate resumed (e.g. new changes replicating, user went back online)
        console.log("Sync resumed:");
        store.dispatch(meetIsSyncing(meetId));
        store.dispatch(meetIsActivelySyncing(meetId));
      })
      .on("denied", (err) => {
        // a document failed to replicate (e.g. due to permissions)
        // TODO: do we want to shut off sync just cause one doc couldn't sync
        disableSync(meetId);
        console.log("Sync denied:", err);
      })
      .on("complete", (info) => {
        console.log("Sync complete:", info);
        store.dispatch(meetIsNotSyncing(meetId));
      })
      .on("error", (err) => {
        console.log("Sync error:", err);
        store.dispatch(meetIsNotSyncing(meetId));
      });
  } else {
    console.log(
      "not logged in. not syncing local meet to online meet at this time"
    );
  }
};

export const syncIfDbShouldSync = function (meetId: string) {
  console.log("Attempting to sync db", meetId);
  const masterDb = getMasterDbInstance();
  masterDb
    .get(meetId)
    .then(function (doc: any) {
      if (doc.online) {
        console.log("meet is online.", meetId);
        syncToCloud(meetId);
      } else {
        console.log("meet is not online. Loading locally", meetId);
        loadMeet(meetId);
      }
    })
    .catch(function (err) {
      console.error("ERROR: getting meet doc in master db", err);
      errorReporter({
        message: "syncIfDbShouldSync ERROR: getting meet doc in master db",
        error: err,
      });
    });
};

export const createOnlineAccount = async function (
  meetId: string,
  password: string,
  confirmationToken: string,
  emailAddress: string,
  meetName: string
) {
  try {
    const result = await fetchWrapper("/apiv2/meets", "POST", {
      meet_id: meetId,
      meet_pass: password,
      confirmation_token: confirmationToken,
      email_address: emailAddress,
      meet_name: meetName,
    });
    if (result.ok) {
      console.log("Successful meet creation");
    } else {
      console.log("ERROR", result);
      alert(result.error);
      throw new TypeError("token failed");
    }
  } catch (error: any) {
    if (error.message !== "token failed") {
      errorReporter({ message: "Error uploading meet", error });
      alert("Something went wrong uploading meet");
    }

    throw error;
  }
};

export const uploadDbToCloud = function (
  meetId: string,
  meetName: string,
  openPasswordModal: (action: string) => Promise<{
    password: string;
    confirmationToken: string;
    emailAddress: string;
  }>
) {
  return openPasswordModal("set").then(
    ({ password, confirmationToken, emailAddress }) => {
      // user may have hit cancel but we still end up here and want to resolve this promise.
      if (!password) {
        return;
      }
      return createOnlineAccount(
        meetId,
        password,
        confirmationToken,
        emailAddress,
        meetName
      )
        .then(() => {
          return login(meetId, password, () => Promise.resolve({ password }));
        })
        .then(() => {
          // mark in masterDb that this meet is syncing
          const masterDb = getMasterDbInstance();
          masterDb
            .get(meetId)
            .then(function (doc: any) {
              doc.online = true;
              masterDb
                .put(doc)
                .then(function (response) {
                  console.log("Marked local meet as syncing online", response);
                  updateAttributesOnDocument(meetId, meetId, {
                    payment_status: "UNPAID",
                    validated: true,
                  });
                })
                .catch(function (err) {
                  console.error("ERROR: putting meet doc in master db", err);
                  errorReporter({
                    message: "ERROR: putting meet doc in master db",
                    error: err,
                  });
                });
            })
            .catch(function (err) {
              console.error("ERROR: getting meet doc in master db", err);
              errorReporter({
                message: "ERROR: getting meet doc in master db",
                error: err,
              });
            });
        })
        .catch((err) => {
          console.error("Error creating online account", err);
          errorReporter({
            message: "Error creating online account",
            error: err,
          });
        });
    }
  );
};

export const loadMeetsOnStartup = function () {
  const masterDb = getMasterDbInstance();
  masterDb
    .changes({
      since: 0,
      live: true,
      include_docs: true,
    })
    .on("change", async (change) => {
      if (change.doc?._id) {
        const meetId = change.doc._id;
        if (change.deleted) {
          store.dispatch(removeMeet(meetId));
        } else {
          try {
            const db = getMeetDbInstance(meetId);
            // only load meet document
            const doc = await db.get(meetId, { conflicts: true });
            handleDatabaseChange({
              doc: doc as unknown as GenericDocument,
              isDeleted: false,
              meetId,
              isInitialLoad: true,
              store,
            });
          } catch (e) {
            // if this fails it is probably because this ran after login and the meet has not synced locally yet.
            // TODO: seems this can happen if the meet exists in the master db but the individual meet db is not there.
            console.log(e);
          }
          store.dispatch(meetIsLocal(meetId));
        }
      }
    });
};

export const initMeet = async function (meetId: string) {
  let isOnlineReadOnly = true;
  try {
    const masterDb = getMasterDbInstance();
    const result = await masterDb.allDocs({ key: meetId });
    isOnlineReadOnly = result.rows.length === 0;
  } catch (e: any) {
    console.error("Error accessing master db:", e);
    errorReporter({ message: "initMeet Error accessing master db", error: e });
  }

  if (!isOnlineReadOnly) {
    await checkAuthStatus(meetId).catch((error) => {
      console.log("Error checking auth status", error);
      errorReporter({ message: "initMeet checkAuthStatus error", error });
    });
  }

  if (isOnlineReadOnly) {
    console.log("DB doesn't exist locally, connecting online");

    // TODO: this timeout deals with an issue where a locally deleted meet
    // will still have a master db document with a deleted flag.
    // This causes the a removeMeet action to be dispatched.
    // This timeout causes the addMeet action to be dispactched after the removeMeet action.
    store.dispatch(startLoadingMeet(meetId));
    setTimeout(() => {
      loadMeet(meetId, getReadOnlyOnlineMeetDbInstance(meetId));
    }, 200);
  } else {
    syncIfDbShouldSync(meetId);
  }
};
